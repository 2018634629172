import { useMultipleMetadataParams } from 'lib/api-hooks';
import { assertDefined } from 'lib/guards';
import { useScrollIntoView } from 'lib/hooks';
import { useParams } from 'wouter';

import OrganCard from './OrganCard';
import styles from './OrganList.module.css';

const NAMES = {
  sample: 'Sample_name',
};

function OrganList() {
  const { donor } = useParams();
  assertDefined(donor);
  const scrollRef = useScrollIntoView(donor);

  const queryParam = `SamplePatient_number~eq~${donor}`;
  const organUnique = useMultipleMetadataParams(NAMES, queryParam);

  return (
    <div ref={scrollRef} className={styles.sampleContainer}>
      {/* <div className={styles.sampleContainer}> */}
      {organUnique.sample?.map((o) => <OrganCard key={o} organ={o} />)}
    </div>
  );
}

export default OrganList;
