import { useToggle } from '@react-hookz/web';
import { useNeuroglancerUrl } from 'lib/api-hooks';
import { trackLink } from 'lib/helpers';
import { useRef } from 'react';
import { FiExternalLink } from 'react-icons/fi';

import styles from './NeuroglancerWindow.module.css';

interface Props {
  resourceId: string;
}

function NeuroglancerWindow(props: Props) {
  const { resourceId } = props;
  const [showNeuroglancer, toggleNeuroglancer] = useToggle(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const neuroglancerLink = useNeuroglancerUrl(resourceId);

  return (
    <div>
      <a
        className={styles.neuroglancerNewTab}
        href={`${neuroglancerLink}`}
        target="_blank"
        rel="noreferrer"
        data-alt2
        onClick={(evt) => trackLink(evt)}
      >
        <span>
          Neuroglancer visualisation in new tab <FiExternalLink />
        </span>
      </a>
      <div className={styles.neuroglancerContainer}>
        {!showNeuroglancer ? (
          <div className={styles.emptyNeuroglancer}>
            <button
              className={styles.neuroglancerButton}
              type="button"
              onClick={() => {
                toggleNeuroglancer(true);
              }}
            >
              Click for Neuroglancer visualisation on page
            </button>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            className={styles.neuroglancerWindow}
            src={`${neuroglancerLink}`}
            title="neuroglancer-frame"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        )}
      </div>
    </div>
  );
}
export default NeuroglancerWindow;
