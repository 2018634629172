import { ExploreLayer } from 'lib/explore-layout';
import { usePageTracking } from 'lib/hooks';
import { Helmet } from 'react-helmet';
import { Route } from 'wouter';

import DatasetList from './DatasetList';
import DonorList from './DonorList';
import OrganList from './OrganList';

function ExplorePage() {
  usePageTracking();

  return (
    <>
      <Helmet>
        <title>Explore</title>
      </Helmet>
      <div>
        <Route path="/" nest>
          <ExploreLayer title="Donors">
            <DonorList />
          </ExploreLayer>
        </Route>
        <Route path="/:donor" nest>
          <ExploreLayer title="Organs">
            <OrganList />
          </ExploreLayer>
        </Route>

        <Route path="/:donor/:organ" nest>
          <ExploreLayer title="Datasets">
            <DatasetList />
          </ExploreLayer>
        </Route>
      </div>
    </>
  );
}

export default ExplorePage;
