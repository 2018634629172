import { Boundary } from 'lib/boundary';
import type { ReactNode } from 'react';
import { useLocation } from 'wouter';

import styles from './ExploreLayer.module.css';

interface Props {
  title: string;
  children: ReactNode;
}
function ExploreLayer(props: Props) {
  const { title, children } = props;
  const [location] = useLocation();

  return (
    <div className={styles.wrapper}>
      <h1 key={title} className={styles.sectionTitle}>
        {title}
      </h1>
      <Boundary resetKeys={[location]}>{children}</Boundary>
    </div>
  );
}

export default ExploreLayer;
