import type { ComponentType } from 'react';

import { useIcatSessionId } from '../api-hooks';
import type { ProcessedDataset } from '../api-models';
import Pagination from '../Pagination/Pagination.tsx';
import styles from './Results.module.css';

interface DatasetCardProps {
  dataset: ProcessedDataset;
  sessionId: string;
}

interface Props {
  Card: ComponentType<DatasetCardProps>;
  filteredDatasets: ProcessedDataset[];
  isStale: boolean;
}

function Results(props: Props) {
  const { Card, filteredDatasets, isStale } = props;
  const sessionId = useIcatSessionId();

  const paginationInfo = filteredDatasets[0].meta;

  return (
    <div className={styles.wrapper}>
      <Pagination paginationInfo={paginationInfo} loading={isStale} />
      <div
        className={styles.paginationResults}
        data-stale={isStale || undefined}
      >
        {filteredDatasets?.map((d) => (
          <Card key={d.id} dataset={d} sessionId={sessionId} />
        ))}
      </div>
      <Pagination paginationInfo={paginationInfo} loading={isStale} bottom />
    </div>
  );
}

export default Results;
