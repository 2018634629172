import { useDatasetsByQuery, useIcatSessionId } from 'lib/api-hooks';
import styles from 'lib/Explore/DatasetList.module.css';
import { assertDefined } from 'lib/guards';
import { useScrollIntoView } from 'lib/hooks';
import { useParams } from 'wouter';

import DatasetCard from '../Search/DatasetCard';

function DatasetList() {
  const sessionId = useIcatSessionId();
  const { donor, organ } = useParams();
  assertDefined(donor);
  assertDefined(organ);

  const scrollRef = useScrollIntoView(organ);

  const queryParam = `Sample_name~like~${donor}_${organ}`;
  const datasets = useDatasetsByQuery(queryParam);

  return (
    <div ref={scrollRef} className={styles.datasetContainer}>
      {datasets.map((d) => (
        <DatasetCard key={d.id} dataset={d} sessionId={sessionId} />
      ))}
    </div>
  );
}

export default DatasetList;
