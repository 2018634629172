import stylesToc from 'lib/TableOfContent.module.css';
import { Helmet } from 'react-helmet';

import contrastBeforeAfter from '../../images/contrast-before-after.png';
import iconMeasurement from '../../images/icon-measurement.png';
import importOptions from '../../images/import-options.png';
import macroInputOuput from '../../images/macro-input-output.png';
import measurementExample from '../../images/measurement-example.png';
import BackToHelp from '../BackToHelp';
import styles from './FijiTutorial.module.css';
import ToCFiji from './ToCFiji';

interface Props {
  isDesktop: boolean;
}

function FijiTutorial(props: Props) {
  const { isDesktop } = props;
  return (
    <>
      <Helmet title="Tutorial - Fiji" />

      <div className={styles.wrapper}>
        {isDesktop ? (
          <aside className={stylesToc.tocAside}>
            <div className={stylesToc.tocSticky}>
              <BackToHelp />
              <ToCFiji />
            </div>
          </aside>
        ) : (
          <>
            <BackToHelp />
            <details>
              <summary className={stylesToc.tocSummary}>
                Basic Image Processing with FIJI/ImageJ
              </summary>
              <div className={stylesToc.tocContent}>
                <ToCFiji />
              </div>
            </details>
          </>
        )}
        <main className={styles.main}>
          {isDesktop && (
            <h1 className={stylesToc.tocTitle}>
              Basic Image Processing with FIJI/ImageJ
            </h1>
          )}
          <section id="preamble" className={styles.tutorialSection}>
            <h2 className={styles.sectionTitle}>Preamble</h2>
            <p className={styles.paragraph}>
              Fiji is an open-source image processing package that is built on
              ImageJ, a widely used image analysis tool. The name "Fiji" is
              actually a recursive acronym that stands for "Fiji Is Just
              ImageJ." It extends the capabilities of the original ImageJ
              software by bundling it with a set of useful plugins that
              facilitate scientific image analysis.
            </p>
            <p className={styles.paragraph}>
              In this tutorial, we will use this{' '}
              <a
                className={styles.inlineLink}
                href="/datasets/571998122"
                target="_blank"
                rel="noreferrer"
              >
                <span />
                <span>dataset</span>
                <span />
              </a>{' '}
              to illustrate the different operation performed with Fiji.
            </p>
            <ul>
              <li>
                Click on the button to download
                "211.04um_FO-20.129_lung-left_upper-lobe_pag-0.15_0.03_jp2_"
              </li>
              <li>
                Click "I understand" on the message that remind you to cite the
                publication
              </li>
            </ul>
            <p className={styles.paragraph}>
              We use the bin4 (downsampled by a factor 4) version of the volume
              because the full resolution volume's size is too large to be
              loaded by most computer.
            </p>
          </section>

          <section id="downloadFiji" className={styles.tutorialSection}>
            <h2 className={styles.sectionTitle}>Download FIJI/ImageJ</h2>
            <p className={styles.paragraph}>
              Go to the{' '}
              <a
                className={styles.inlineLink}
                href="https://fiji.sc"
                target="_blank"
                rel="noreferrer"
              >
                <span />
                <span>Fiji website</span>
                <span />
              </a>{' '}
              to illustrate the different operation performed with Fiji.
            </p>
            <p className={styles.paragraph}>
              Select the version that corresponds to your operating system
              (Windows, macOS, and Linux) and click the download button. Install
              Fiji: Once downloaded, simply uncompress the file. Fiji doesn't
              require installation process. To launch the program, all you need
              to do is double-click the 'fiji.app' file.
            </p>
          </section>

          <section id="gettingStarted" className={styles.tutorialSection}>
            <h2 className={styles.sectionTitle}>Getting started</h2>

            <div>
              <h3 className={styles.subsectionTitle}>
                &nbsp;&nbsp;&nbsp;&nbsp; Opening an image stack
              </h3>
              <p className={styles.paragraph}>
                Common image format (.jpg, .tif, …) can be opened by dragging
                and dropping the folder or file onto the Fiji main control panel
                or with File &gt; Import &gt; Image Sequence For opening more
                complex files (.jp2, nd2, …) such as the image stack downloaded
                in the Preamble, drag and drop an image of the stack onto the
                Fiji main control panel or do File &gt; Import &gt; Bio-Formats
                and select an image of the image stack.
              </p>
              <img
                className={styles.image}
                src={importOptions}
                alt="Screenshot showing the 'Bio-Formats Import Options' panel"
                width="580"
                height="395"
              />
              <p className={styles.paragraph}>
                <strong>“Group files with similar names”</strong> has to be
                checked in order to open the complete stack
              </p>
              <p className={styles.paragraph}>
                <strong>“Use virtual stack”</strong> can be checked if the
                volume size is too large and your computer cannot handle it,
                this option make Fiji only load the slice you are looking at. It
                is very useful for opening large datasets.
              </p>
            </div>

            <div>
              <h3 className={styles.subsectionTitle}>
                &nbsp;&nbsp;&nbsp;&nbsp; Navigating in your volume
              </h3>
              <p className={styles.paragraph}>
                <strong>Moving in z direction:</strong> Use the scrollbar at the
                bottom of the image window to navigate through the stack. Each
                position on the scrollbar represents a different slice in the
                stack. You can also use the arrow keys
              </p>
              <p className={styles.paragraph}>
                <strong>Zooming:</strong> Use either “+” and “-” to zoom-in or
                zoom-out, or use the top and bottom arrow keys.
              </p>
              <p className={styles.paragraph}>
                <strong>Orthogonal views:</strong> If you want to see orthogonal
                views of your dataset, click on Image &gt; Stack &gt; Orthogonal
                views. This is only possible if you are not in Virtual Stack
                mode.
              </p>
            </div>

            <div>
              <h3 className={styles.subsectionTitle}>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp; Adjust the contrast
              </h3>
              <p className={styles.paragraph}>
                To adjust the contrast of the image, go in Image &gt; Adjust
                &gt; Brightness/Contrast and click on “Auto”.
              </p>
              <img
                className={styles.image}
                src={contrastBeforeAfter}
                alt="Screenshot showing the difference in contrast before and after an 'auto' adjustment"
                width="515"
                height="390"
              />
              <p className={styles.paragraph}>
                <strong>Apply to stack:</strong> If you want to apply these
                settings to the entire stack, click 'Apply'.
              </p>
            </div>

            <div>
              <h3 className={styles.subsectionTitle}>
                {' '}
                &nbsp;&nbsp;&nbsp;&nbsp;Measurement
              </h3>
              <p className={styles.paragraph}>
                If you want to measure a distance:
              </p>
              <ul>
                <li>
                  Click on the line icon{' '}
                  <img
                    src={iconMeasurement}
                    alt="Measurement icon in FIJI"
                    width="23"
                    height="24"
                  />
                </li>
                <li>Draw the distance you want to measure</li>
                <li>Click on Analyse &gt; Measure</li>
                <li>
                  Multiply the length (given in pixel) with the voxel size
                  (present in the name of the folder)
                </li>
              </ul>
              <img
                className={styles.image}
                src={measurementExample}
                alt="Example of a measurement made on an airway of a lung"
                width="602"
                height="336"
              />
              <p className={styles.paragraph}>
                In this example, the diameter of the airway is 18.151 x 105.52 =
                1915 μm
              </p>
            </div>
          </section>

          <section id="exampleMacro" className={styles.tutorialSection}>
            <h2 className={styles.sectionTitle}>
              Small Macro Example with Bonej
            </h2>
            <p className={styles.paragraph}>
              Macro can be used in Fiji to automatize processes. Here we will
              show an example using the plugin{' '}
              <a
                className={styles.inlineLink}
                href="https://bonej.org/"
                target="_blank"
                rel="noreferrer"
              >
                <span />
                <span>BoneJ</span>
                <span />
              </a>
              .
            </p>
            <ul>
              <li>
                Download the macro: <strong>BoneJ_analysis.ijm</strong> and the
                data: <strong>Raw_data_control</strong> from this{' '}
                <a
                  className={styles.inlineLink}
                  href="https://www.dropbox.com/scl/fo/s1ti3hwsrmm9nht5pyjfj/h?rlkey=d4waq3dlawn0egt2zi7nv6m1b&dl=0"
                  target="_blank"
                  rel="norefferer noreferrer"
                >
                  <span />
                  <span>Dropbox repository</span>
                  <span />
                </a>
              </li>
              <li>
                Drag and drop the macro onto the Fiji main control panel. The
                macro will appear in a new window.
              </li>
              <li>Click on Run in the new window</li>
              <li>
                Fill the input directory with the path to{' '}
                <strong>Raw_data_control</strong>. Fill the output directory
                where you want. The File suffix is .tif
              </li>
              <img
                className={styles.image}
                src={macroInputOuput}
                alt="Panel asking for the input and output directory and the file suffix"
                width="462"
                height="213"
              />
              <li>
                After some calculation, indicate the results folder in the
                “Choose a directory” window.
              </li>
              <li>
                After some time you should obtain color maps of the alveoli and
                struts thicknesses.
              </li>
            </ul>
          </section>
        </main>
      </div>
    </>
  );
}

export default FijiTutorial;
